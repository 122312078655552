import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import VueI18n from "vue-i18n";

import 'video.js/dist/video-js.css'



Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: localStorage.getItem("lang") || "vi_vn",
	messages: {
		vi_vn: require("./assets/languages/vi_VN.json"),
		zh_cn: require("./assets/languages/zh_CN.json"),
		en_us: require("./assets/languages/en_US.json"),
		th_th: require("./assets/languages/th_TH.json"),
		ja_jp: require("./assets/languages/ja_JP.json"),
		ko_kr: require("./assets/languages/ko_KR.json"),
	},
});
new Vue({
  store,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
