<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({ path: '/WithdrawRecord' })">{{ $t("history_withdraw") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="bankinfo" v-if="this.bank">{{ this.bank.info.bankinfo }} ({{ this.bank.info.bankid.substring(0, this.bank.info.bankid.length - 5) }}*****)</div>
      <div class="withdrawMoney">
        <span style="color:#c24491;font-size:18px;font-weight:bold">{{ $t("money_withdraw") }}</span>
        <div class="money">
          <div class="moneyNumber">
            <!--<span class="moneyType">vnđ</span>-->
            <van-field v-model="withdraw_money" type="number" :placeholder="$t('pls_money')" />
          </div>
          <span class="all" @click="allMoney()">{{ $t("all") }}</span>
        </div>
        <div class="information" style="display:flex;justify-content:space-between;align-items: center">
          <div class="balance">
            <span>{{ $t("money") }}: </span>
            <span class="number">{{ Number(this.userInfo.money).toLocaleString() }}</span>
          </div>
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1. {{ $t("limit") }}: {{ $t("min") }} {{ this.withdrawRole.min }}，{{ $t("max") }} {{ this.withdrawRole.max }}</p>
                <p>2. {{ $t("times") }} {{ $t("withdraw") }}: {{ $t("max") }} {{ this.withdrawRole.num }} / {{ $t("day") }}</p>
                <p>3. {{ $t("time") }}: {{ $t("note_withdraw") }}</p>
              </div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{ $t("note") }}
              </template>
            </van-popover>
          </div>
        </div>
        <!--<div class="money" style="display:block">
          <div class="moneyNumber">
            <van-field v-model="pass2" type="password" :placeholder="$t('pls_paypwd')"/>
          </div>
        </div>-->
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{ $t("confirm") }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      pass2: "",
      userInfo: {},
      withdrawRole: {},
      bank: {},
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserWithdrawRole() {
      this.$http({
        method: "get",
        url: "user_get_withdraw_role",
      }).then((res) => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    allMoeny() {
      this.withdraw_money = Math.floor(this.userInfo.money);
    },
    doWithdraw() {
      /*if(this.pass2 === "" || this.pass2 === null || this.pass2 === undefined){
        this.$toast.fail(this.$t("pls_paypwd"));
        return false;
      }*/
      if (this.withdraw_money <= 0) {
        this.$toast(this.$t("pls_money"));
        return false;
      } else {
        this.$http({
          method: "post",
          data: { money: this.withdraw_money, pass2: this.pass2 },
          url: "user_set_withdraw",
        }).then((res) => {
          if (res.code === 200) {
            //location. reload()
            this.$toast(res.msg);
            //this.getUserInfo();
            //this.getUserWithdrawRole();
            this.$router.push({ path: "/WithdrawRecord" });
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    },
    getUserBankInfo() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.bank = res.data;
          } else {
            this.$router.push("BindCard");
            this.$toast.fail(this.t('pls_bind_bank'));
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
      this.getUserBankInfo();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 15px;
  line-height: 30px;
}
.page {
  background: #fff;
  height: 100vh;
}
.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 16px;
  color: #dc2037;
}
.container .main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: calc(100% - 20px);
  position: relative;
}
.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 20px 20px 0;
  white-space: nowrap;
  font-size: 15px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #ced4da;
  padding: 5px 15px 5px 10px;
  font-size: 14px;
  line-height: 1;
}
::v-deep .van-field__control {
  line-height: 2;
  font-size: 18px;
}
.container .main .withdrawMoney .money .moneyNumber {
  font-size: 20px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all {
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 20px;
  padding: 0 !important;
}
.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 40px;
  line-height: 1.22667px;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #c24491, #775fd9);
}
.bankinfo {
  font-size: 18px;
  color: #c24491;
  border-top: 15px solid #f2f2f2;
  border-bottom: 15px solid #f2f2f2;
  padding: 15px 20px;
}
</style>
