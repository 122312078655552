<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('language')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="$router.push({path: 'Home'})" />
				</template>
			</van-nav-bar>
		</div>
		<div class="content">
			<div class="wrap_box">
				<div class="item" @click="changeLang('zh_cn', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/zh_cn.png" /></div>
						<div class="info">简体中文</div>
					</div>
					<div v-if="lang == 'zh_cn'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('en_us', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/en_us.png" /></div>
						<div class="info">English</div>
					</div>
					<div v-if="lang == 'en_us'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('th_th', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/th_th.png" /></div>
						<div class="info">ภาษาไทย</div>
					</div>
					<div v-if="lang == 'th_th'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('vi_vn', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/vi_vn.png" /></div>
						<div class="info">Tiếng Việt</div>
					</div>
					<div v-if="lang == 'vi_vn'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('ja_jp', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/ja_jp.png" /></div>
						<div class="info">日本語</div>
					</div>
					<div v-if="lang == 'ja_jp'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('ko_kr', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/ko_kr.png" /></div>
						<div class="info">한국어</div>
					</div>
					<div v-if="lang == 'ko_kr'">✓</div>
				</div>
			</div>
			<!--<div class="wrap_box">
				<div class="item" @click="changeLang('ms_my', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/ms_my.png" /></div>
						<div class="info">Melayu</div>
					</div>
					<div v-if="lang == 'ms_my'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('tr_tr', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/tr_tr.png" /></div>
						<div class="info">Türkçe</div>
					</div>
					<div v-if="lang == 'tr_tr'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('es_es', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/es_es.png" /></div>
						<div class="info">Español</div>
					</div>
					<div v-if="lang == 'es_es'">✓</div>
				</div>
			</div>
			<div class="wrap_box">
				<div class="item" @click="changeLang('zh_hk', $event)">
					<div class="left flex_center">
						<div><img src="/img/language/zh_hk.png" /></div>
						<div class="info">繁體中文</div>
					</div>
					<div v-if="lang == 'zh_hk'">✓</div>
				</div>
			</div>-->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {},
			lang: this.$i18n.locale || "vi_vn",
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		changeLang(lang) {
			this.$toast.loading({
				mask: true,
				duration: 200,
			});
			this.lang = lang;
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
			window.location.reload();
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.t("offline"));
						localStorage.removeItem("token");
						this.$router.push({path: "/Login"});
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			this.getUserInfo();
		}
	},
	mounted() {},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.wrap_box {
	border-bottom: 1px solid #cfcfcf;
	padding: 8px 20px;
	font-size: 14px;
}
.wrap_box > .item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wrap_box > .item > .flex_center {
	display: flex;
	align-items: center;
}
.wrap_box > .item > .flex_center img {
	height: 30px;
	margin-right: 10px;
	position: relative;
	top: 2px;
}
.wrap_box > .item > div:not(.flex_center) {
	color: green;
	font-weight: bold;
	font-size: 20px;
}
</style>
