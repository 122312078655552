import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from "vue-i18n";
import Home from '../pages/home/index.vue'
import Mine from '../pages/mine/index.vue'
import Choose from '../pages/choose/index.vue'
import List from '../pages/choose/list.vue'
import Profile from '../pages/choose/profile.vue'
import Video from '../pages/video/index.vue'
import Game from '../pages/game/index.vue'
import Login from '../pages/login/index.vue'
import Register from '../pages/login/register.vue'
import ServiceOnline from '../pages/mine/ServiceOnline.vue'
import ServicePage from '../pages/mine/ServicePage.vue'
import Setting from '../pages/mine/Setting.vue'
import Infomation from '../pages/mine/Infomation.vue'
import Setname from '../pages/mine/Setname.vue'
import Setsex from '../pages/mine/Setsex.vue'
import SetPayPassword from '../pages/mine/SetPayPassword.vue'
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'
import Lottery from '../pages/lottery/index.vue'
import Notice from '../pages/mine/Notice.vue'
import PlayVideo  from '../pages/video/PlayVideo'
import Setbank  from '../pages/mine/Setbank'
import BindCard  from '../pages/mine/BindCard'
import Recharge from '../pages/mine/Recharge'
import Withdraw  from '../pages/mine/Withdraw'
import Personalreport  from '../pages/mine/Personalreport'
import GameRecord  from '../pages/mine/GameRecord'
import WithdrawRecord  from '../pages/mine/WithdrawRecord'
import RechargeRecord  from '../pages/mine/RechargeRecord'
import Language  from '../pages/more/Language'

Vue.use(VueRouter)
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: localStorage.getItem("lang") || "vi_vn",
	messages: {
		vi_vn: require("../assets/languages/vi_VN.json"),
		zh_cn: require("../assets/languages/zh_CN.json"),
		en_us: require("../assets/languages/en_US.json"),
		th_th: require("../assets/languages/th_TH.json"),
		ja_jp: require("../assets/languages/ja_JP.json"),
		ko_kr: require("../assets/languages/ko_KR.json"),
	},
});
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'home'}},
    {path:'/Home',name:'home',component:Home,meta:{title:'home'}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:'heaven'}},
	{path:'/List',name:'list',component:List,meta:{title:'Danh sách'}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:'mine'}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:'mine'}},
    {path:'/Video',name:'video',component:Video,meta:{title:'cinema'}},
    {path:'/Game',name:'game',component:Game,meta:{title:'lobby'}},
    {path:'/Login',name:'login',component:Login,meta:{title:'login'}},
    {path:'/Register',name:'register',component:Register,meta:{title:'register'}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:'service'}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:'service'}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:'setting'}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:'info'}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:'setname'}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:'sex'}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:'paypwd'}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:'change_pwd'}},
    {path:'/Lottery',name:'Lottery',component:Lottery,meta:{title:'orders'}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:'notice'}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:'cinema'}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:'bind_bank'}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:'bind_bank'}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:'withdraw'}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:'recharge'}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:'account_detail'}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:'history_withdraw'}},
    {path:'/RechargeRecord',name:'RechargeRecord',component:RechargeRecord,meta:{title:'history_recharge'}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:'history_play'}},
    {path:'/Language',name:'Language',component:Language,meta:{title:'language'}},
];


const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         
    //document.title = to.matched[0].meta.title
    document.title = i18n.t(to.matched[0].meta.title);
    next()
})

export default router